import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { style } from './breadcrumb.styles';

@customElement('sbk-breadcrumb-item')
export class SbkBreadcrumbItem extends LitElement {

  @property()
  href = '';

  static get styles() {
    return style;
  }

  connectedCallback() {
    super.connectedCallback();
    this.setAttribute('role', 'listitem');
  }

  render() {
    return html`
      <div>
        ${this.href
            ? html`<sbk-link variant="regular" size="xs" href=${this.href}><slot></slot></sbk-link>`
            : html`<span aria-current="page"><slot></slot></span>`}
      </div>
    `;
  }
}
